import styles from "./livesession.module.scss";
import Image from "next/image";
import { captialize } from "../../../utils/helper";
import { AstroImages } from "../../../modules/Astro/constants";
import CountryIcon from "../CountryIcon/CountryIcon";
import { COUNTRY_CODES, sessionType } from "../../../types/common.types";
import { LEVEL_ICON } from "../../../utils/imageRelativePaths";
import { useCallback } from "react";
import { useRouter } from "next/navigation";

export default function LiveSessionCard(props: { session: sessionType }) {
  const { liveRoom, userProfile } = props.session;
  const router = useRouter();
  const onPostCardClick = useCallback((roomId: number) => {
    // here if live is there then we will go to feed page on press
    // otherwise for audio we will go to /room/${id} page
    if (roomId) {
      void router.push(`/live/feed?roomId=${roomId}`);
    }
  }, []);
  return (
    <div
      className={styles["each_session"]}
      key={liveRoom.userId}
      onClick={() => onPostCardClick(liveRoom.id)}
    >
      <div className={styles["layer"]} />
      <Image
        src={liveRoom.roomIcon}
        width={100}
        height={100}
        className={styles["expert_img"]}
        alt={"user"}
      />
      <div className={styles["session_top"]}>
        <p>{liveRoom.languages.join(" | ")}</p>
        <div className={styles["live"]}>
          <div />
          <p>Live</p>
        </div>
      </div>
      <div className={styles["expert_details"]}>
        {captialize(userProfile.name)}{" "}
        <Image src={AstroImages.BADGE} alt={""} />
      </div>
      <div className={styles["expert_rating"]}>
        {userProfile.country ? (
          <CountryIcon
            countryCode={COUNTRY_CODES[
              userProfile.country as keyof typeof COUNTRY_CODES
            ]?.toUpperCase()}
          />
        ) : (
          ""
        )}
        <Image
          className={styles.postImage}
          src={`${LEVEL_ICON}/${userProfile.level}.svg`}
          width={45}
          height={18}
          alt="viewers-icon"
        />
        {/*3 Yrs Exp | &#9733; 4.1(5676)*/}
      </div>
    </div>
  );
}
