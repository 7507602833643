import styles from "./ourastro.module.scss";
import Image from "next/image";
import { AstroImages } from "../constants";
import { RoomResponse, sessionType } from "../../../types/common.types";
import { AstrologerCard } from "../../../common/components/AstrologerCard";
import { useRouter } from "next/navigation";
import { handleCardClick } from "../RoomsExplore/components/PostsContainer/PostsContainer";
export default function OurAstrologers({
  sessions,
}: {
  sessions: RoomResponse[];
}) {
  const router = useRouter();

  const onViewMoreClick = () => {
    router.push("/call");
  };
  return (
    <div className={styles["our_astrologers_cont"]}>
      <p className={styles["section_heading"]}>OUR astrologers</p>
      <button className={styles["view_more_btn"]} onClick={onViewMoreClick}>
        view More <Image src={AstroImages.RIGHT_ARROW} alt={"More"} />
      </button>

      <div className={styles["astrologers_cont"]}>
        {sessions.map((astrologerSession, index) => (
          <AstrologerCard
            session={astrologerSession}
            key={astrologerSession.liveRoom.userId}
            handleClick={() =>
              handleCardClick(astrologerSession.userProfile.userName, router)
            }
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
