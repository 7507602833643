import { Config } from "../config";

export const INITIAL_LIST_SIZE = 25;
export const LIST_SIZE = 10;
export const MAX_LIST_SIZE = Number.MAX_SAFE_INTEGER;
export const CONST_DELAY = 500;

export const MONTH_TIME = 30 * 24 * 60 * 60 * 1000;
export const WEEK_TIME = 7 * 24 * 60 * 60 * 1000;
export const DAY_TIME = 24 * 60 * 60 * 1000;
export const DAILY_REPORT_LIST_SIZE = 20;
// TODO: to change this before going live

export const PAGE_LINKS = {
  level: "/level",
};

export const NATIVE_BACK_PATH = `${
  Config.NEXT_PUBLIC_CHINGARI_HOST_URL || ""
}/action/backpress`;

// OG Meta Constants
export const APP_STORE_ID = "1450361582";
export const TWITTE_APP_ID_IPHONE = "1526811171";
export const AUDIO_ROOM_META_DESCRIPTION =
  "Join Audio room in chingari, share gifts with the community and win Gari everyday";
export const LIVE_ROOM_META_DESCRIPTION =
  "Join Live room in chingari, share gifts with the community and win Gari everyday";

// room selected
export const ROOM_SELECTED_TITLE = "Selected Rooms";
export const TH_ROOM_ID = "Room ID";
export const TH_CITY = "City";

export const LOCALISATION_URLS = [/\/leaderboard/i];

export const ROOM_URL = "/room";
export const AUDIO_TOGGLE = "TAP TO UNMUTE";
