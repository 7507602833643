import styles from "./live.module.scss";
import Image from "next/image";
import {AstroImages} from "../constants";
import { ROOM_TYPE, sessionType } from "../../../types/common.types";
import LiveSessionCard from "../../../common/components/LiveSessionCard";
import { useRouter } from "next/navigation";

export default function LiveSessions({
  sessions,
}: {
  sessions: sessionType[];
}) {
  const router = useRouter();

  const onViewMoreClick = () => {
    router.push("/live");
  };

  return (
    <div className={styles["live_session_cont"]}>
      <Image
        src={AstroImages.LIVE_SESSION_BACKGROUND}
        className={styles["background"]}
        alt={""}
      />
      <p className={styles["section_heading"]}>LIVE SESSIONS</p>
      <button className={styles["view_more_btn"]} onClick={onViewMoreClick}>
        view More <Image src={AstroImages.RIGHT_ARROW} alt={"More"} />
      </button>
      <div className={styles["sessions_cont"]}>
        {sessions.map((session, index) => (
          <LiveSessionCard session={session} key={index} />
        ))}
      </div>
    </div>
  );
}
