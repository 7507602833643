import {AstroImages} from "@/modules/Astro/constants";

export const ClientReviews = [
  {
    id: 1,
    rating: 4,
    name: "Ananya Sharma",
    img: AstroImages.FACE_1,
    location: "Mumbai, Maharashtra",
    review:
      "I have been using AstroLive for the past month, and it has been an amazing experience. The interface is sleek and user-friendly, making it easy to navigate. The astrologers are very knowledgeable and provide insightful guidance. The Live Chat feature is particularly helpful, allowing me to get instant answers to my questions. Highly recommended for anyone seeking guidance from good astrologers!",
  },
  {
    id: 2,
    rating: 5,
    name: "Rajesh Kumar",
    location: "Delhi",
    img: AstroImages.FACE_2,
    review:
      "AstroLive has quickly become my favorite astrology platform. The variety of astrologers and their expertise is truly impressive. I consulted for a career-related query, and the guidance I received was spot on. Despite being a new platform, it operates smoothly and efficiently. Kudos to the AstroLive team for creating such a fantastic service!",
  },
  {
    id: 3,
    rating: 5,
    name: "Priya Patel",
    location: "Ahmedabad, Gujarat",
    img: AstroImages.FACE_3,
    review:
      "AstroLive is a great platform with a lot to offer. The navigation is intuitive, and I love how easy it is to find and consult with different astrologers. The astrologers are experienced, and their readings have been very accurate. I am very impressed with this new platform!",
  },
  {
    id: 4,
    rating: 5,
    name: "Vikram Singh",
    location: "Bangalore, Karnataka",
    img: AstroImages.FACE_4,
    review:
      "I've been using AstroLive for about a month, and it's been a wonderful experience. The live sessions and the ability to consult astrologers based on their domains, like Vedic and Tarot, are excellent features. The platform is new but already shows great promise with its user-friendly design and reliable service. Looking forward to more features and improvements. Great job, AstroLive team!",
  },
];
