"use client"
import styles from './download.module.scss'
import Image from "next/image";
import {AstroImages, ASTRO_APP_LINKS} from "../constants";
export default function DownloadBanner() {
  const openAppLink = (store: "PLAY_STORE" | "APP_STORE") => {
    window.open(ASTRO_APP_LINKS[store], "_blank")
  }
  return (
    <div className={styles["download_banner_cont"]}>
      <Image src={AstroImages.LIVE_SESSION_BACKGROUND} className={styles['background']} alt={''}/>
      <Image src={AstroImages.GIFT_IMG} className={styles['gift']} alt={''}/>
      <Image src={AstroImages.STAR_IMG} className={styles['star']} alt={''}/>
      <Image src={AstroImages.STAR_IMG} className={styles['star2']} alt={''}/>

      <div className={styles['banner_text']}>
        <p>Download our app &
          get your first consultation for free</p>
        <div className={styles['btn_cont']}>
          <button onClick={() => openAppLink("APP_STORE")}><Image src={AstroImages.APP_STORE}
                                                                  className={styles['app_store']} alt={""}/>Apple Store
          </button>
          <button onClick={() => openAppLink("PLAY_STORE")}><Image src={AstroImages.PLAY_STORE}
                                                                   className={styles['app_store']} alt={""}/>Google Play
          </button>
        </div>
      </div>
      <div className={styles['banner_img']}>
        <Image src={AstroImages.SCREENSHOT3} className={styles['screenshot1']} alt={''}/>
      </div>
    </div>
  )
}