"use client"
import styles from './client.module.scss'
import Image from "next/image";
import {AstroImages} from "../constants";
import {useRef} from "react";
import { ClientReviews } from './data';
export default function HappyClient(){
  const quickLinksRef=useRef<HTMLDivElement>(null)
  const handleNavigation=(next?:boolean)=>{
    const isNext=next ?? false
    if (quickLinksRef?.current){
      // scroll
      const scrollWidth = quickLinksRef.current.children[0].clientWidth * 1.10
      quickLinksRef.current.scrollTo({
        left: quickLinksRef.current.scrollLeft + (scrollWidth * (isNext ? 1 : -1)),
        behavior: 'smooth'
      });
    }
  }
  return (
    <div className={styles['happy_client_cont']}>
      <p className={styles['section_heading']}>our happy clients</p>
      <div className={styles['client_parent_cont']}>
        <Image src={AstroImages.PREV_ICON} alt={"Prev"} className={styles['prev']} onClick={()=>handleNavigation()}/>
        <div className={styles['client_cont']} ref={quickLinksRef}>
          {
            ClientReviews.map(({id, name, review, location, img}, index) =>
              <div className={styles['each_client']} key={id}>
                <Image src={img} alt={"client"} className={styles['client_img']}/>
                <Image src={AstroImages.QUOTE} alt={""} className={styles['quote']}/>
                <p className={styles['review']}>{review}</p>
                <div className={styles['details']}>
                  <div className={styles['name']}>
                    <p>{name}</p>
                    <p>{location}</p>
                  </div>
                  {/*<div className={styles['tag']}>Fashion</div>*/}
                </div>
              </div>
            )
          }
        </div>
        <Image src={AstroImages.PREV_ICON} alt={"next"} className={styles['next']} onClick={()=>handleNavigation(true)}/>
      </div>

    </div>
  )
}